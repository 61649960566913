import React, { useState } from "react";
import {
  Box,
  CssBaseline,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import _ from "lodash";
import { motion } from "framer-motion";
import { theme } from "../../themeV2";
import Seo from "../../components/seo";
import Container from "../../components/Container";
import HeroLanding from "../../components/HeroLanding";
import ProductModal from "../../components/ProductModal";
import SecondaryButton from "../../components/SecondaryButton";
import CardColor from "../../components/CardColor";
import Database from "../../components/icons/Database";
import BadgeCheck from "../../components/icons/BadgeCheck";
import ArrowsTurnToDots from "../../components/icons/ArrowsTurnToDots";
import Header from "../../components/Header";
import fidelityImage from "../../images/products/regulatory-reporting/fidelity.png";
import temImage from "../../images/products/regulatory-reporting/image.png";
import fileImageGreen from "../../images/icons/rr-green-02.png";
import fileImageOrange from "../../images/icons/rr-orange-02.png";
import fileImagePurple from "../../images/icons/purple.png";
import regulatoryVideoPoster from "../../images/products/regulatory-reporting/regulatory-video-poster.png";
import ModalFooterCookies from "../../components/landings/ModalFooterCookies";
const regulatoryVideo = "https://app.allfunds.com/docs/cms/rr_video_efd43ee6b4.mp4";

const metadata = {
  title: "Regulatory Reporting",
  description: "Accede gratis al producto de REGTECH.",
};

const colorIcon = theme.palette.colors.orange.dark;

const RRSTREAMLines = [
  {
    icon: <Database color={colorIcon} />,
    title: <Trans>Robust Data Management</Trans>,
    description: (
      <Trans>
        Leverage our comprehensive database for accurate PRIIPs KID completion with crucial information such as NAV history and cost details.
      </Trans>
    ),
  },
  {
    icon: <BadgeCheck color={colorIcon} />,
    title: <Trans>Efficient Validation Process</Trans>,
    description: (
      <Trans>
        Utilize our advanced control and monitoring tools to validate PRIIPs KID, ensuring accuracy and compliance every step of the way.
      </Trans>
    ),
  },
  {
    icon: <ArrowsTurnToDots color={colorIcon} />,
    title: <Trans>Direct Distribution </Trans>,
    description: (
      <Trans>
        Allfunds connects fund houses directly with distributors for seamless document dissemination and accurate updates.
      </Trans>
    ),
  },
];

const REGDATAS = [
  {
    color: "green",
    icon: fileImageGreen,
    title: <Trans>EMT</Trans>,
    description: (
      <Trans>
        Easily comply with MiFID II regulations, ensuring all necessary information is accurately captured and reported.
      </Trans>
    ),
  },
  {
    color: "orange",
    icon: fileImageOrange,
    title: <Trans>EPT</Trans>,
    description: (
      <Trans>
        Streamline your obligations under the PRIIPs regulation with tailored assistance designed to meet all reporting requirements.
      </Trans>
    ),
  },
  {
    color: "purple",
    icon: fileImagePurple,
    title: <Trans>EET</Trans>,
    description: (
      <Trans>
        Transparently showcase your funds' sustainable profiles by leveraging the EET, meeting the increasing demand for ESG information and compliance.
      </Trans>
    ),
  },
];

const getAnimationProps = (direction) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: 0.8 },
  transition: { duration: 0.8 },
  variants: {
    hidden: { opacity: 0, y: direction === "down" ? 70 : -70 },
    visible: { opacity: 1, y: 0 },
  },
});

function AnimatedDiv({ isMobile, direction, children }) {
  if (isMobile) {
    return <div>{children}</div>;
  }
  return <motion.div {...getAnimationProps(direction)}>{children}</motion.div>;
}

function RegulatoryReporting() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [subTitle, setSubTitle] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request Demo</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description }) => {
    return (
      <CardColor color={color} icon={icon} title={title} description={description} />
    );
  };

  const renderStreamLines = ({ icon, title, description }) => {
    return (
      <Grid item xs={12} sm={10} md={10} lg={4}>
        <Stack spacing={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={1} sm={1.5} md={2}>
              { icon }
            </Grid>
          </Grid>
          <Typography>
            <b>{title}</b>
          </Typography>
          <Typography color={theme.palette.colors.blue.light}>
            {description}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Seo title={metadata.title} description={metadata.description} />
      <CssBaseline />
      <ModalFooterCookies />
      <Box>
        <Header />
        <HeroLanding
          title={
            <Trans>Enhance compliance and streamline publication processes.</Trans>
          }
          description={
            <Trans>
              Allfunds’ Regulatory Reporting platform ensures compliance with regulations and streamlines efficient document distribution, including KID PRIIPs, EMT, EPT, EET, Factsheets, and ESG reports. (Principal Adverse Impact and EU Taxonomy analysis provided by MainStret Partner).
            </Trans>
          }
          handleOnclickInfo={() => handleOnclickInfo("Regulatory Reporting")}
          multimedia={regulatoryVideo}
          kind="Regulatory Reporting"
          kindColor={theme.palette.colors.orange.dark}
          isVideo={true}
          videoPoster={regulatoryVideoPoster}
        />
        <Stack
          py={6}
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ paddingRight: "0px !important", background: theme.palette.colors.grey.light }}
        >
          <Container>
            <Grid container spacing={4} alignItems="center" justifyContent="space-between">
              <Grid item md={3.5} display={{xs: "none", md: "none", lg: "inline-block"}}>
                <motion.div
                  className=""
                  initial="left"
                  whileInView="right"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 0.8 }}
                  variants={{
                    left: { opacity: 0, x: -100 },
                    right: { opacity: 1, x: 0 },
                  }}
                >
                  <img
                    src={fidelityImage}
                    alt="Regulatory Reporting img"
                    style={{ width: "100%" }}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12} md={12} lg={7.5}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Typography
                      variant="text"
                      color={theme.palette.colors.orange.dark}
                    >
                      <Trans>
                        Regulatory Reporting
                      </Trans>
                    </Typography>
                    <Typography
                      variant="h2"
                    >
                      <Trans>
                        Streamline Your Reporting Needs
                      </Trans>
                    </Typography>
                  </Stack>
                  <Typography
                    color={theme.palette.colors.blue.light}
                  >
                    <Trans>
                      Our platform goes beyond basic compliance, offering a suite of tools for effective regulatory reporting:
                    </Trans>
                  </Typography>
                  <Stack spacing={2}>
                    <Stack
                      spacing={{
                        xs: 2,
                        md: 1,
                      }}
                      direction={{ xs: "column", sm: "colum", lg: "row" }}
                      justifyContent="center"
                      alignItems={{ xs: "center", sm: "center", lg: "normal" }}
                      useFlexGap
                    >
                      {RRSTREAMLines.map(({ icon, title, description }) => (
                        renderStreamLines({ icon, title, description })
                      ))}
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: {xs: "center", lg: "flex-start"},
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {renderRequestButton("Regulatory Reporting")}
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Stack>
        <Stack
          py={6}
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ paddingRight: "0px !important" }}
        >
          <Container>
            <Grid container spacing={4} alignItems="center" justifyContent="center">
              <Grid item xs={12} md={12} order={0}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Typography
                      variant="text"
                      textAlign="center"
                      color={theme.palette.colors.orange.dark}
                    >
                      Regulatory Reporting
                    </Typography>
                    <Typography
                      variant="h2"
                      textAlign="center"
                      mt={12}
                    >
                      <Trans>
                        Comprehensive European Templates
                      </Trans>
                    </Typography>
                  </Stack>
                  <Stack
                    xs={12}
                    display={{
                      xs: "none",
                      md: "none",
                      lg: "flex",
                    }}
                    width="100%"
                    alignItems="center"
                  >
                    {renderRequestButton("Regulatory Reporting")}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={8} lg={6} order={{xs: 2, sm: 2, lg: 1}} spacing={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item width="100%" xs={12} sm={6} sx={{ paddingRight: { xs: 0, sm: 1 } }}>
                    <AnimatedDiv isMobile={isMobile} direction="down">
                      {REGDATAS.slice(0, 2).map((item) => (
                        renderCard({ ...item, isHovered: hoveredCard, setIsHovered: setHoveredCard })
                      ))}
                    </AnimatedDiv>
                  </Grid>
                  <Grid item width="100%" xs={12} sm={6} sx={{ paddingLeft: { xs: 0, sm: 1 } }}>
                    <AnimatedDiv isMobile={isMobile} direction="up">
                      {renderCard({ ..._.last(REGDATAS), isHovered: hoveredCard, setIsHovered: setHoveredCard })}
                    </AnimatedDiv>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8} lg={6} order={{xs: "unset", sm: "unset", lg: 2}}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <motion.img
                    src={temImage}
                    alt="Regulatory Reporting img"
                    initial="right"
                    whileInView="left"
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.8 }}
                    variants={{
                      right: { opacity: 0, x: 100 },
                      left: { opacity: 1, x: 0 },
                    }}
                    style={isTablet ? { width: "70%" } : {width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Stack
                pt={{
                  xs: 3,
                  sm: 4,
                }}
                pl={4}
                xs={12}
                display={{
                  xs: "flex",
                  md: "flex",
                  lg: "none",
                }}
                width="100%"
                alignItems="center"
                order={{xs: 3, sm: 3, lg: "unset"}}
              >
                {renderRequestButton("Regulatory Reporting")}
              </Stack>
            </Grid>
          </Container>
        </Stack>
      </Box>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="REGTECH - Regulatory reporting"
          product="regulatory-reporting"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </ThemeProvider>
  );
}

export default RegulatoryReporting;
